
import React from "react";
export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="social">
          <h3>Contact Us</h3>
          <ul>
            <li>
              <a href={`tel:${props.data ? props.data.phone : "/"}`}>
                <i className="fa fa-phone"></i>
                <span className="social-text">{props.data ? props.data.phone : "/"}</span>
              </a>
            </li>
            <li>
              <a href={`mailto:${props.data ? props.data.email : "/"}`}>
                <i className="fa fa-envelope-o"></i>
                <span className="social-text">{props.data ? props.data.email : "/"}</span>
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.facebook : "/"}>
                <i className="fa fa-facebook"></i>
                <span className="social-text">Facebook</span>
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.instagram : "/"}>
                <i className="fa fa-instagram"></i>
                <span className="social-text">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
