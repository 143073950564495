import React from "react";

export const Services = (props) => {
  return (
    <div id="our-mission" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Mission</h2>
          <p>
If 2020 has shown us anything, it’s that we all need to know that someone cares. The challenges of the
pandemic, coupled with economic anxiety, have driven many of us to higher levels of anxiety, loneliness,
and physical pain. Perhaps no where are these challenges more evident than in the agents of mercy,
those frontline, essential, and community charity based workers who witness the hurt on a daily basis.
These are the people on which the members of Heal It Forward Canada are focused.
Far too often we forget that these heroes are in need of healing themselves. A little TLC as it were.
Spending hours on end on the move, standing, transporting these community angels need tending to
their bodies and minds. Heal it Forward Canada is a non-profit created for just those purposes. To let
our heroes know that there is a safe space for them to gain a moment of respite and healing of tired
bodies. 
</p>
<br></br>
<p>
Through a collaboration of medical and healing professionals, we provide services of healing
through massage and touch. Our members have graciously donated their time and expertise to heal
those who heal others. We are driven with an underlying belief in “paying it forward”. By encouraging
this drive in ourselves and in others, Heal It Forward Canada hopes to spread the movement of reaching
out and giving back, one human at a time.
Wherever healing and our services are needed in the community, it will be our mission at Heal It
Forward Canada to step up and step in. All are welcome. Love, healing and rejuvenation are what we’re
all about. You are not forgotten nor taken for granted. Please know we are here, and we truly do care.
It is in that spirit we look forward to seeing you and hopefully provide a boost to your daily lives. See you
soon!
          </p>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
